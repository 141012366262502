import Alpine from 'alpinejs';
import FormSet from "../components/FormSet";

// We put a python booleans straight into the template
window.True = true;
window.False = false;


Alpine.data('unload', () => ({
    init() {
        window.addEventListener("beforeunload", (event) => {
            const target = event.target.activeElement;
            if (target.tagName === "BUTTON" || target.classList.contains("no-unload")) {
                // Don't prevent form submissions
                return;
            }
            event.preventDefault();
            // Legacy
            event.returnValue = true;
            return true;
        });
    }
}))

Alpine.data('abnLookup', (abnLookupUrl = '') => ({
    url: abnLookupUrl,
    init() {
        this.initEventListeners();
    },
    initEventListeners() {
        this.$el.addEventListener('change', (e) => {
            this.lookupAbn(e.target.value);
        })
    },
    lookupAbn(abn) {
        fetch(`${this.url}?abn=${abn}`).then(response => response.json()).then(data => {
            if (Object.keys(data).length === 0) {
                return;
            } else {
                for (const [key, value] of Object.entries(data)) {
                    const input = document.querySelector(`[name="${key}"]`);
                    const event = new Event("change");
                    if (input) {
                        input.value = value;
                        input.dispatchEvent(event);
                    }
                }
            }
        })
    }

}))

Alpine.data('formset', FormSet)

Alpine.data("sortableTable", (defaultSort = 'Reference') => ({
    sortBy: defaultSort, // default is just visual, it's what the table is sorted by from the server
    sortAsc: false,
    sortByColumn(column, $el) {
        if (this.sortBy === column) {
            this.sortAsc = !this.sortAsc;
        } else {
            this.sortBy = column;
        }

        this.getTableRows()
            .sort(
                this.sortCallback(
                    Array.from($el.parentNode.children).indexOf(
                        $el
                    )
                )
            )
            .forEach((tr) => {
                this.$refs.tbody.appendChild(tr);
            });
    },
    getTableRows() {
        return Array.from(this.$refs.tbody.querySelectorAll("tr"));
    },
    getCellValue(row, index) {
        const child = row.children[index];
        return child.dataset.sortValue || child.innerText;
    },
    sortCallback(index) {
        // date is YYYY-MM-DD
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        return (a, b) => {
            const row1 = this.getCellValue(this.sortAsc ? a : b, index);
            const row2 = this.getCellValue(this.sortAsc ? b : a, index);

            if (dateRegex.test(row1) && dateRegex.test(row2)) {
                const date1 = new Date(row1);
                const date2 = new Date(row2);
                return date1 - date2;
            }

            return row1 !== "" &&
                    row2 !== "" &&
                    !isNaN(row1) &&
                    !isNaN(row2)
                    ? row1 - row2
                    : row1.toString().localeCompare(row2);
        }

    }
}))


window.Alpine = Alpine
Alpine.start()
